<template>
  <div class="" id="new_gallery">
    <Preloader v-if="!loaded" />
    <div class="container-fluid bg-light" v-if="items !==null">
      <div class="row">
        <h3 class="pt-1 fw-bold" style="font-family: Rockwell">{{siteTitle}}'s Some Special Moments</h3>
      </div>
        <CoolLightBox
            :items="items"
            :index="index"
            :effect="'fade'"
            :useZoomBar="true"
            :fullScreen="true"
            @close="index = null">
        </CoolLightBox>

        <div class="images-wrapper">
          <div class="row">
            <div
                class="image col-lg-3 col-md-4 col-sl-6 p-0"
                v-for="(image, imageIndex) in items"
                :key="imageIndex"
                @click="index = imageIndex"
                :style="{ backgroundImage: 'url(' + image.src || image.thumb + ')' }">
              <img class="border border-1 border-white" v-if="image.type=='image'" :src="image.src" style="width: 100%" alt="">
              <img class="border border-1 border-white" v-if="image.type=='video'" :src="image.thumb" style="width: 100%" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  import CoolLightBox from 'vue-cool-lightbox'
  import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
  import Preloader from "../components/Preloader";

  export default {
    components:{
      Preloader,
      CoolLightBox,
    },
    data: function () {
      return {
        items: null,
        index: null,
        loaded:false
      };
    },
    beforeCreate() {
      this.$http.get('get-gallery-image').then(response => {
        this.items = response.body;
      })
    },
    mounted() {

    },
    updated() {
      this.loaded = true;
    },
    computed:{
      siteTitle(){return this.$store.getters.getSiteTitle;}
    },
  };
</script>

<style scoped>

</style>